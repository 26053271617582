<template class="mt-0">
<div class="h-100">
  <div class="bg-white shadow-sm px-5 py-3 position-relative side-user">
      <div class="d-flex justify-content-between align-items-center">
        <img src="../assets/image/logo.jpg" width="200" heigth="100">
        <div class="d-flex align-items-center">
          
        </div>
      </div>
  </div>
  <div class="p-1 mr-5 mt-3 ml-3 align-items-center">
    <router-view class="w-100" />
  </div>
</div>
  
</template>

<script>
export default {
  name: "guestView"
};
</script>

<style>
  body{
    margin: 0 !important;
  }
</style>
